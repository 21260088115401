import prestashop from 'prestashop';

$(document).ready(() => {

    const base_url = ajax_link_option

    $(document).on('click', '.js-guiltek-option-set[data-ajax-state="connected"]', (e) => {
        const data_product = e.target.getAttribute('data-product')
        const id_option = e.target.getAttribute('data-option')
        const id_cart = e.target.getAttribute('data-cart')

        let data = fetch(base_url, {
            method: "POST",
            body: JSON.stringify({
                cart: id_cart,
                option: id_option,
                product: data_product,
                toggle: true
            })
        }).then(reponse => {
            if(reponse.ok){
                prestashop.emit('updateCart', {reason: 'update', resp: {cart: prestashop.cart}})
            }else{
                throw new Error('Something wrong with webservice')
            }
        }).catch((error) => {
            console.log(error)
        })
    })

    const $body = $('body');

    $body.on('click', '[data-option][data-button-action="add-to-cart"]', (event) => {
        event.preventDefault();

        const options = document.querySelectorAll('.js-guiltek-option-set[data-ajax-state="mixed"]')
        let filter   = Array.prototype.filter
        const filtered = filter.call( options, option => {
            return option.checked;
        });
        filtered.forEach((option) => {
            const data_product = option.getAttribute('data-product')
            const id_option = option.getAttribute('data-option')
            const id_cart = option.getAttribute('data-cart')

            fetch(base_url, {
                method: "POST",
                body: JSON.stringify({
                    cart: id_cart,
                    option: id_option,
                    product: data_product,
                    toggle: false
                })
            })
        })
    })

});